
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CompanyFindByIdPublic } from '@/settings/application/uses_cases/company/search/CompanyFindByIdPublic';
import { showAlert } from '@/core/plugins/Notifications';
import i18n from '@/core/plugins/i18n';

@Component({
  name: 'CustomerRegistration',
  components: {}
})
export default class ValidateCompany extends Vue {
  @Inject(TYPES.FINDBYID_COMPANY_PUBLIC)
  readonly companyFindByIdPublic!: CompanyFindByIdPublic;

  isLoading = false;

  created() {
    this.getCompanyData();
  }

  async getCompanyData() {
    const urlParams = new URLSearchParams(window.location.search);
    const companyId = urlParams.has('companyId') ? (urlParams.get('companyId') as string) : '';
    localStorage.setItem('businessId', companyId);
    this.isLoading = true;

    await this.companyFindByIdPublic
      .execute(companyId)
      .then((res: any) => {
        if ('error' in res || 'message' in res) {
          this.isLoading = false;
          showAlert('warning', i18n.t('general.error404'));
          return;
        }

        localStorage.setItem('companyData', JSON.stringify(res));
        this.isLoading = false;
        this.$router.replace({ name: 'Login' });
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }
}
